/* 
  We declare font faces for both the Qasa and Blocket fonts here.
  Only one font family will be utilized in the deployed app. However, as the browser 
  doesn't download unused fonts, this approach is acceptable.

  While it may slightly increase the CSS file size, the impact is currently negligible.
*/

/* QASA FONTS */
@font-face {
  font-family: 'Qasa Diatype Rounded';
  src: url('/fonts/qasa/QasaDiatypeRounded-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Qasa Diatype Rounded';
  src: url('/fonts/qasa/QasaDiatypeRounded-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Qasa Diatype Rounded';
  src: url('/fonts/qasa/QasaDiatypeRounded-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Qasa Diatype Rounded Semi-Mono';
  src: url('/fonts/qasa/QasaDiatypeRoundedSemi-Mono-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Qasa Diatype Rounded Semi-Mono';
  src: url('/fonts/qasa/QasaDiatypeRoundedSemi-Mono-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

/* BLOCKET FONTS */
@font-face {
  font-family: 'BlocketSans';
  src: url('/fonts/blocket/BlocketSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'BlocketSans';
  src: url('/fonts/blocket/BlocketSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'BlocketSans';
  src: url('/fonts/blocket/BlocketSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'BlocketSans';
  src: url('/fonts/blocket/BlocketSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
