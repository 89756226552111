html,
body {
  height: 100%;
}
body {
  height: 100%;
  /* TODO: find a better solution for the cookie consent padding issue */
  padding: 0 !important;
  overflow-y: auto;
}

/* Need this to override the aria message from @react-aria/live-announcer that is displayed via the @react-aria calendar */
div[data-live-announcer] {
  white-space: normal !important;
}
/* Schibsted cookie consent inherits pointerEvents: none from the body when the dialog is open at the same time. Which locks the user in the iframe. */
iframe[title='SP Consent Message'] {
  pointer-events: auto;
}
